"use server";
import {
  getBrand,
  getCoinsSummary,
  getGiftBoxV3,
  getPlutoGiftPublic,
  getUserProfile,
} from "../../apis/server_apis";
import { Header } from "~/types";
import { getCookie } from "vinxi/http";
import { redirect } from "@solidjs/router";
import { VoucherProduct } from "~/server/types/brand";
import { APIError } from "~/utils/fetch";
import { getPlutoReceiverSessionIdStorageKey } from "~/components/gifting/pluto-receiver/pluto-receiver-landing/pluto_reveal_route_data";
import { PlutoGiftDetails } from "~/server/types/gift";

export type PlutoRedeemRouteData = {
  coinsCount: number;
  brand: VoucherProduct;
  isPhoneNumberLinked: boolean;
  isRtu: boolean;
  heading: string;
};

export type PlutoRedeemRouteDataProps = {
  giftingKey: string;
  brandKey: string;
  isPreview: boolean;
  amount: number;
};

export async function getPlutoRedeemRouteData(
  props: PlutoRedeemRouteDataProps
): Promise<PlutoRedeemRouteData> {
  if (props.isPreview) {
    let brand = await getBrand(
      props.brandKey,
      {},
      {
        isUnauthenticated: true,
        throwAuthError: true,
      }
    );
    let plutoGiftDetails = await getPlutoGiftPublic(props.giftingKey);

    return {
      coinsCount: props.amount,
      brand: brand,
      isPhoneNumberLinked: false,
      isRtu: false,
      heading: extractTitleFromPlutoGift({
        brandId: props.brandKey,
        plutoGiftDetails: plutoGiftDetails,
      }),
    };
  }

  let sessionId = getCookie(
    getPlutoReceiverSessionIdStorageKey(props.giftingKey)
  );
  if (!sessionId) {
    throw redirect("/pluto/reveal/" + props.giftingKey);
  }

  let coinsCount$P = getCoinsSummary(
    {
      [Header.SessionId]: sessionId,
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  let getVoucherProduct$P = getBrand(
    props.brandKey,
    {
      [Header.SessionId]: sessionId,
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  let userProfile$P = getUserProfile(
    {
      [Header.SessionId]: sessionId,
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  let giftMetadata$P = getGiftBoxV3(
    props.giftingKey,
    {
      [Header.SessionId]: sessionId,
      [Header.FpDeviceToken]: "",
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  try {
    const resolved = await Promise.all([
      coinsCount$P,
      getVoucherProduct$P,
      userProfile$P,
      giftMetadata$P,
    ]);

    let coinsSummary = resolved[0];
    let brand = resolved[1];
    let profile = resolved[2];
    let giftMetadata = resolved[3];

    return {
      coinsCount: coinsSummary.totalAvailable,
      brand: brand,
      isPhoneNumberLinked:
        profile.phoneNumber !== null && profile.phoneNumber !== "",
      isRtu: coinsSummary.totalConsumed > 0,
      heading: extractTitleFromPlutoGift({
        brandId: props.brandKey,
        plutoGiftDetails: giftMetadata.plutoGiftDetails!,
      }),
    };
  } catch (error) {
    if (error instanceof APIError && error.code === 401) {
      throw redirect("/pluto/reveal/" + props.giftingKey);
    }
    throw error;
  }
}

function extractTitleFromPlutoGift(props: {
  brandId: string;
  plutoGiftDetails: PlutoGiftDetails;
}): string {
  let list = props.plutoGiftDetails?.activities
    ? Object.values(props.plutoGiftDetails?.activities)
    : [];

  let brandList = props.plutoGiftDetails?.brandDetails ?? [];

  let brand = brandList.find((item) => item.brandId === props.brandId);

  return (
    list.find((item) => item.brandKey === brand?.brandKey)?.description ?? ""
  );
}
