import {
  cache,
  createAsync,
  useParams,
  useSearchParams,
} from "@solidjs/router";
import { onMount, Show } from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import PlutoRedeemGiftCardComponent from "~/components/gifting/pluto-receiver/pluto-receiver-redeem/pluto_redeem_component";
import { getPlutoRedeemRouteData } from "~/server/data/personalised_gifting/pluto_redeem_route_data";
import attachPopStateListener from "~/utils/popstate_listener";

const RedeemGiftCardRoute$C = cache(
  getPlutoRedeemRouteData,
  "getPlutoRedeemRouteData"
);

export default function RedeemGiftCardRoute() {
  const params = useParams();
  const giftingKey = params.giftingKey$ as string;
  const brandKey = params.brandKey as string;
  const [queryParams, setQueryParams] = useSearchParams();

  const isPreview = queryParams.isPreview === "true";
  const amount = Number(queryParams.amount ?? "5000");

  const routeData = createAsync(
    async () => {
      return await RedeemGiftCardRoute$C({
        giftingKey,
        brandKey,
        isPreview,
        amount,
      });
    },
    { deferStream: true }
  );

  onMount(() => {
    attachPopStateListener();
  });

  return (
    <>
      <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
      <ClientOnlyComponent component={ClientComponent.ModalHost} />

      <Show when={routeData()}>
        <PlutoRedeemGiftCardComponent
          brand={routeData()!.brand}
          onOrderInitiateSuccess={async (orderId) => {
            window.location.replace(
              "/pluto/reveal/" + giftingKey + "/buy/" + brandKey + "/" + orderId
            );
          }}
          coinsAvailable={routeData()!.coinsCount}
          giftingKey={giftingKey}
          phoneLinked={routeData()!.isPhoneNumberLinked}
          isRtu={routeData()!.isRtu}
          logoUrl={""}
          isPreview={isPreview}
          heading={routeData()!.heading}
        />
      </Show>
    </>
  );
}
